import React, { useState } from 'react';
import './home.sass'
import tools1 from '../../images/tools/tool1.png'
import tools2 from '../../images/tools/tool2.png'
import tools3 from '../../images/tools/tool3.png'
import tools4 from '../../images/tools/tool4.png'
import tools5 from '../../images/tools/tool5.png'
import tools6 from '../../images/tools/tool6.png'
import tools7 from '../../images/tools/tool7.png'
import { Link } from 'react-router-dom';

function StakePage() {

  return (
    <div className="home">
        <h1>THE ULTIMATE GODLEN UTILITY DApp FOR SOLANA DEGENERATES</h1>
        <p>Get involved in crypto like top degen without knowing any basics. Hold GODLEN and utilise our DApp tools such as - sniper bots, token launchers, trackers, chain scanners and more...</p>
        <div className='homeBody'>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENstake</h3>
                <p>Earn rewards by staking tokens, or create your own customisable staking pool in seconds.</p>
                <Link className='linkHome' to="/solana/godlen/stake">Launch</Link>
                <img
                    src={tools1}
                    height={170}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENtapper</h3>
                <p>Earn free token's by playing telegram tapper game, or create own tailored to your community needs.</p>
                <a className='linkHome' href="https://godlenmemetapper.godlenfish.com/">Launch</a>
                <img
                    src={tools2}
                    height={182}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENsend</h3>
                <p>Send tokens to hundreds of wallets with just a few clicks—multisender that simplifies bulk transactions.</p>
                <span className='comingSoonHome'>Coming soon</span>
                <img
                    src={tools3}
                    height={163}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENlauncher</h3>
                <p>Effortlessly create your Solana SPL or other chain token with our simple process—no coding required.</p>
                <Link className='linkHome' to="/solana/launcher">Launch</Link>
                <img
                    src={tools4}
                    height={210}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENsniper</h3>
                <p>Sniper bot that makes profit easy—just provide a contract address and investment details, and we handle the rest.</p>
                <span className='comingSoonHome'>Coming soon</span>
                <img
                    src={tools5}
                    height={150}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENtracker</h3>
                <p>Telegram bot that announces all incoming transactions to specified SOL wallet or Smart Contract.</p>
                <span className='comingSoonHome'>Coming soon</span>
                <img
                    src={tools6}
                    height={170}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
            <div className="ourToolsRow">
                <div className="toolImageWrap">
                <h3>GODLENscan</h3>
                <p>Simple on-chain data analytics tool that can export all details of any SOL wallet or SOL contract, including token holders, transactions and more...</p>
                <span className='comingSoonHome'>Coming soon</span>
                <img
                    src={tools7}
                    height={160}
                    className="ourToolsImage"
                    alt="The Godlenfish ourTools"
                    />
                </div>
            </div>
        </div>
    </div>
  );
}

export default StakePage;
