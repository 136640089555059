import "./stakingPools.sass";
import React, { useState, useEffect } from "react";
import Pool from "./Pool";
import Popup from "reactjs-popup";
import CreatePool from "./CreatePool";
import StakeToken from "./StakeTokens";
import { fetchAllVaultsWithMeta } from "../../../../utils/helpers";
import { useConnection } from "@solana/wallet-adapter-react";
import { programId } from "../../../../utils/config";

const plus = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447716 6 1V6H1C0.447715 6 0 6.44772 0 7C0 7.55228 0.447716 8 1 8H6V13C6 13.5523 6.44771 14 7 14C7.55228 14 8 13.5523 8 13V8H13C13.5523 8 14 7.55228 14 7C14 6.44772 13.5523 6 13 6H8V1Z"
      fill="#FAB10D"
    />
  </svg>
);
const closeIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.39106 11.7203C-0.130354 12.2417 -0.130353 13.0871 0.39106 13.6085C0.912474 14.1299 1.75785 14.1299 2.27927 13.6085L6.99964 8.88813L11.7206 13.6091C12.242 14.1305 13.0874 14.1305 13.6088 13.6091C14.1303 13.0877 14.1303 12.2423 13.6088 11.7209L8.88785 6.99992L13.6085 2.27927C14.1299 1.75785 14.1299 0.912473 13.6085 0.39106C13.0871 -0.130354 12.2417 -0.130353 11.7203 0.391061L6.99964 5.11172L2.2796 0.391675C1.75819 -0.129738 0.912809 -0.129738 0.391396 0.391676C-0.130018 0.913089 -0.130017 1.75847 0.391397 2.27988L5.11144 6.99992L0.39106 11.7203Z"
      fill="#020304"
    />
  </svg>
);

function StakingPools() {
  const { connection } = useConnection();

  const [showCreatePool, setShowCreatePool] = useState(false);
  const [showStakeTokens, setShowStakeTokens] = useState(false);
  const [stakingPool, setStakingPool] = useState();
  const [pools, setPools] = useState([]);

  const closeModalCreatePool = () => setShowCreatePool(false);
  const closeModalStakeTokens = () => setShowStakeTokens(false);

  useEffect(() => {
    if (!connection) {
      return;
    }

    const fetchData = async () => {
      const pools = await fetchAllVaultsWithMeta(connection, programId);
      setPools(pools);
    };

    fetchData().catch(console.error);
  }, [connection]);

  return (
    <div className="stakingPools">
      <div className="headerPools">
        <h2>Pools</h2>
        <button onClick={() => setShowCreatePool(true)}>{plus} Create new pool</button>
      </div>
      <div className="poolsBody">
        {pools.map((pool, index) => (
          <Pool setShowStakeTokens={setShowStakeTokens} setStakingPool={setStakingPool} poolInfo={pool} key={index} />
        ))}
      </div>
      <Popup className="modalWrapper" open={showCreatePool} closeOnDocumentClick onClose={closeModalCreatePool}>
        <div className="modal">
          <button className="close" onClick={closeModalCreatePool}>
            {closeIcon}
          </button>
          <CreatePool />
        </div>
      </Popup>
      <Popup className="modalWrapper" open={showStakeTokens} closeOnDocumentClick onClose={closeModalStakeTokens}>
        <div className="modal">
          <button className="close" onClick={closeModalStakeTokens}>
            {closeIcon}
          </button>
          <StakeToken pool={stakingPool} />
        </div>
      </Popup>
    </div>
  );
}

export default StakingPools;
