// src/JsonUpload.js
import axios from 'axios';

const JWT = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJhYTAwM2M1Yi0xZDdmLTQ3ZTEtYjRhMC05ODI4MzI2NjZmMTUiLCJlbWFpbCI6ImdvZGxlbmZpc2hAcHJvdG9uLm1lIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImI0NjZmZTlmNWQ1YWNiNjg1MzM2Iiwic2NvcGVkS2V5U2VjcmV0IjoiZGZhNWNmMmFkNTE3MWY3ZDdjYWJiMmY2NzcyMmVjMWJkNzNmN2EyNzMzNTJiZWNhYzRiN2EyOTYxMGIzZmQ4ZiIsImlhdCI6MTcxOTQ3NTgxOH0.CgYxGG3JrEizrD2hWmg6FFH9JCIj8RmWlK-T2UIxFcQ';

const uploadJsonToIPFS = async (name, symbol, description, image) => {
  const jsonData = JSON.stringify({ name, symbol, description, image });
  const blob = new Blob([jsonData], { type: 'application/json' });
  const formData = new FormData();
  formData.append('file', blob, 'metadata.json');

  const pinataMetadata = JSON.stringify({
    name: `${name}_token_metadata`,
  });
  formData.append('pinataMetadata', pinataMetadata);

  const pinataOptions = JSON.stringify({
    cidVersion: 0,
  });
  formData.append('pinataOptions', pinataOptions);

  try {
    const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
      maxBodyLength: 'Infinity',
      headers: {
        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        'Authorization': `Bearer ${JWT}`,
      },
    });
    const link = 'https://gateway.pinata.cloud/ipfs/' + res.data.IpfsHash
    console.log('JSON file uploaded successfully:', link);
    return link
  } catch (error) {
    console.error('Error uploading the JSON file:', error);
    return { error: 'Error uploading the JSON file.' };
  }
};

export default uploadJsonToIPFS;
