import React, {useState} from "react";
import "../../stake.sass";
import MainToken from "./MainToken";
import OtherTokens from "./OtherTokens";

const MyStakes = () => {

  const [tokens, setTokens] = useState(0);

  return (
    <div className="myStakes">
      <div className="buttonsStakesWrap">
        <button className={tokens === 0 ? "active stakeTabButton" : "stakeTabButton"} onClick={() => setTokens(0)}>$GODLEN staking</button>
        <button className={tokens === 1 ? "active stakeTabButton" : "stakeTabButton"} onClick={() => setTokens(1)}>Other stakes</button>
      </div>
      <div className="chooseStakeType">
        {tokens === 1 ? <OtherTokens /> : <MainToken />}
      </div>
    </div>
  );
};

export default MyStakes;
