import { AnchorProvider } from "@coral-xyz/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useMemo } from "react";

const useAnchorProvider = () => {
  const { connection } = useConnection();
  const wallet = useWallet();

  const provider = useMemo(() => {
    if (!wallet || !wallet.publicKey) {
      return null;
    }
    return new AnchorProvider(connection, wallet, {});
  }, [connection, wallet]);

  return provider;
};

export default useAnchorProvider;
