import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import "../../stake.sass";
import { BN } from "@coral-xyz/anchor";
import { feeAccount, programId } from "../../../../utils/config";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress, getMint } from "@solana/spl-token";
import { useEffect, useState } from "react";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import Countdown from "react-countdown";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import {
  calculateRewards,
  fetchAllVaultsWithMeta,
  fetchStakingAccounts,
  fetchVaultData,
  fromTokenAmount,
  getMultiplier,
  sendAndConfirmTnx,
} from "../../../../utils/helpers";
import useAnchorProvider from "../../../../hooks/useAnchorProvider";
import useStakingProgram from "../../../../hooks/useStakingProgram";

const OtherTokens = () => {
  const { connection } = useConnection();
  const walletContext = useWallet();
  const wallet = useAnchorWallet();
  const provider = useAnchorProvider();
  const program = useStakingProgram();

  const [vault, setVault] = useState();
  const [vaults, setVaults] = useState([]);
  const [tokenMint, setTokenMint] = useState();
  const [vaultData, setVaultData] = useState();
  const [stakingData, setStakingData] = useState([]);
  const [decimals, setDecimals] = useState(0);
  const currentDate = new Date();

  useEffect(() => {
    if (!connection) {
      return;
    }

    const fetchData = async () => {
      const vaults = await fetchAllVaultsWithMeta(connection, programId);
      setVaults(vaults);
    };

    fetchData().catch(console.error);
  }, [connection]);

  useEffect(() => {
    if (!provider?.wallet || !vault) {
      setStakingData([]);
      return;
    }

    const fetchPrivateData = async () => {
      const owner = provider.wallet.publicKey;
      const vaultData = await fetchVaultData(vault, connection);
      const mintInfo = await getMint(connection, new PublicKey(vaultData.AllowedToken));
      const stakingData = await fetchStakingAccounts(programId, owner, vault, connection);
      setVaultData(vaultData);
      setStakingData(stakingData);
      setTokenMint(new PublicKey(vaultData.AllowedToken));
      setDecimals(Number(mintInfo.decimals));
    };

    fetchPrivateData().catch(console.error);
  }, [provider?.connection, provider?.wallet?.publicKey, vault]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value !== "") {
      setVault(new PublicKey(value));
    } else {
      setVault();
    }
  };

  const handleUnstake = async (stake) => {
    if (!wallet) throw new WalletNotConnectedError();

    try {
      const userTokenAccount = await getAssociatedTokenAddress(tokenMint, provider.wallet.publicKey);
      const [vaultTokenAccount] = PublicKey.findProgramAddressSync(
        [vault.toBuffer(), Buffer.from("vault_tokens")],
        programId
      );
      const [vaultRewardTokenAccount] = await PublicKey.findProgramAddressSync(
        [Buffer.from("spl_rewards"), vault.toBuffer(), tokenMint.toBuffer()],
        programId
      );

      const tx = await program.methods
        .withdrawTokens(new BN(stake.stakedAmount), true)
        .accounts({
          stakingAccount: stake.accountId,
          userTokenAccount: userTokenAccount,
          vaultTokenAccount: vaultTokenAccount,
          vaultRewardTokenAccount: vaultRewardTokenAccount,
          vault: vault,
          tokenMint: tokenMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          owner: provider.wallet.publicKey,
          feeAccount: feeAccount,
          systemProgram: SystemProgram.programId,
        })
        .signers([])
        .transaction();

        await sendAndConfirmTnx(tx, connection, walletContext)
      alert("Succesfully unstaked!");
    } catch (error) {
      console.error("Error unstaking tokens:", error);
    }
  };

  const handleRewardClaim = async (stake, rewardIndex = 0) => {
    if (!wallet) throw new WalletNotConnectedError();
    try {
      const userTokenAccount = await getAssociatedTokenAddress(tokenMint, provider.wallet.publicKey);
      const [vaultTokenAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("spl_rewards"), vault.toBuffer(), tokenMint.toBuffer()],
        programId
      );

      const tx = await program.methods
        .withdrawTimedRewards(rewardIndex)
        .accounts({
          stakingAccount: stake.accountId,
          userTokenAccount: userTokenAccount,
          vaultTokenAccount: vaultTokenAccount,
          vault: vault,
          tokenMint: tokenMint,
          stakingMint: tokenMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          owner: stake.owner,
          feeAccount: feeAccount,
          systemProgram: SystemProgram.programId,
        })
        .signers([])
        .transaction();

        await sendAndConfirmTnx(tx, connection, walletContext)
      alert("Succesfully claimed rewards!");
    } catch (error) {
      console.error("Error claiming rewards:", error);
    }
  };

  return (
    <div className="myStakesInner">
      <h3>Other stakes</h3>
      <p>Staking rewards can be claimed before staking period ends</p>
      <select value={vault} onChange={handleSelectChange}>
        <option value="">Select Pool</option>
        {vaults?.length > 0
          ? vaults.map((vault, index) => (
              <option value={vault.vault} key={index}>
                {vault.name}
              </option>
            ))
          : null}
      </select>
      {stakingData?.length > 0 ? (
        <div className="stakesContainer">
          <div className="tableHeading">
            <span>Staking amount</span>
            <span>Stake Date</span>
            <span>Unlock Date</span>
            <span>APY</span>
            <span style={{ minWidth: "150px" }}>Claim rewards</span>
            <span style={{ minWidth: "150px" }}>Unstake</span>
          </div>
          <div className="tableBody">
            {stakingData.map((stake, index) => (
              <div className="tableItem" key={index}>
                <span>{fromTokenAmount(stake.stakedAmount, decimals).toFixed(2)} $GODLEN</span>
                <span>{new Date(stake.timestamp * 1000).toUTCString()}</span>
                <span>{new Date(stake.unlockTimestamp * 1000).toUTCString()}</span>
                <span>{getMultiplier(stake.lockupOption, vaultData)} %</span>
                <span>
                  <button
                    onClick={() => {
                      handleRewardClaim(stake);
                    }}
                  >
                    {`Claim ${calculateRewards(stake, vaultData, decimals)}`}
                  </button>
                </span>
                <button
                  onClick={() => {
                    handleUnstake(stake);
                  }}
                  style={{
                    background: stake.unlockTimestamp < currentDate / 1000 ? "#FCECAE" : "#000",
                    color: stake.unlockTimestamp < currentDate / 1000 ? "#000" : "#FAB10D",
                  }}
                >
                  {stake.unlockTimestamp < currentDate / 1000 ? (
                    "Unstake"
                  ) : (
                    <Countdown date={stake.unlockTimestamp * 1000} />
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default OtherTokens;
