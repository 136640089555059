import { useState, useEffect, cloneElement } from "react";
import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import './layout.sass';
import Footer from "../footer/Footer";
import Notifications from "../notifications/Notifications";

function Layout({ children }) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const handleNotification = (text) => {
    const id = Date.now();
    setNotifications(prevNotifications => [...prevNotifications, { id, text }]);
    setTimeout(() => {
      setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
    }, 3000);
  };

  const renderChildrenWithProps = () => {
    if (Array.isArray(children)) {
      return children.map(child => cloneElement(child, { handleNotification }));
    }
    return cloneElement(children, { handleNotification });
  };

  return (
    <div>
      <Header setMobileOpen={setMobileOpen} mobileOpen={mobileOpen} />
      <div className="layout">
        <Sidebar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
        <main className="body">{renderChildrenWithProps()}</main>
      </div>
      <Notifications notifications={notifications} setNotifications={setNotifications} />
      <Footer />
    </div>
  );
}

export default Layout;
