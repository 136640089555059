import { useEffect, useState } from "react";
import "./stakingPools.sass";
import { BN } from "@coral-xyz/anchor";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { SECONDS_IN_DAY, feeAccount, programId } from "../../../../utils/config";
import { Keypair, PublicKey, SystemProgram } from "@solana/web3.js";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { ThreeDots } from "react-loader-spinner";
import useStakingProgram from "../../../../hooks/useStakingProgram";
import { getMint } from "@solana/spl-token";
import { depositRewards } from "../../../../utils/functions";
import { formatAddress, getWalletTokens, sendAndConfirmTnx } from "../../../../utils/helpers";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";

const selectedIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#DD8502" />
    <path d="M7 12.2857L10.4878 16L18 8" stroke="#FFF8C6" strokeLinecap="round" />
  </svg>
);

const selectIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#EBEBEB" />
  </svg>
);

const arrowIcon = (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9.5L7 2.5L13 9.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

function CreatePool() {
  const { connection } = useConnection();
  const walletContext = useWallet();
  const program = useStakingProgram();

  const [selected, setSelected] = useState();
  const [token, setToken] = useState("");
  const [amount, setAmount] = useState("");
  const [selectDuration, setSelectDuration] = useState(0);
  const [apy1, setApy1] = useState("");
  const [apy2, setApy2] = useState("");
  const [apy3, setApy3] = useState("");
  const [apy4, setApy4] = useState("");
  const [duration1, setDuration1] = useState("");
  const [duration2, setDuration2] = useState("");
  const [duration3, setDuration3] = useState("");
  const [duration4, setDuration4] = useState("");
  const [loading, setLoading] = useState(false);
  const [wallTokens, setWallTokens] = useState([]);

  const walletIcon = walletContext.wallet?.adapter?.icon;

  const spinner = (
    <ThreeDots
      visible={loading ? true : false}
      height="16"
      width="40"
      color="#7A3B0D"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );

  useEffect(() => {
    if (!walletContext?.publicKey) {
      return;
    }

    const fetchPrivateData = async () => {
      const tokens = await getWalletTokens(connection, walletContext.publicKey);
      setWallTokens(tokens);
    };

    fetchPrivateData().catch(console.error);
  }, [walletContext?.publicKey]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value !== "") {
      setToken(new PublicKey(value));
    } else {
      setToken();
    }
  };

  const handleSetDurration = async (durration, setOption) => {
    if (Number(durration) < 32) {
      setOption(durration);
    } else {
      setOption(31);
    }
  };

  const handlePoolCreate = async (stake, rewardIndex = 0) => {
    if (!walletContext.connected) throw new WalletNotConnectedError();
    setLoading(true);
    try {
      const allowedToken = new PublicKey(token);
      const vaultRef = Keypair.generate();
      const vaultRefDone = vaultRef.publicKey;
      const [vault] = PublicKey.findProgramAddressSync([Buffer.from("spl_vault"), vaultRefDone.toBuffer()], programId);
      const [vaultTokenAccount] = PublicKey.findProgramAddressSync(
        [vault.toBuffer(), Buffer.from("vault_tokens")],
        programId
      );

      const tx = await program.methods
        .initializeVault(
          allowedToken,
          [
            new BN(SECONDS_IN_DAY).mul(new BN(duration1)),
            new BN(SECONDS_IN_DAY).mul(new BN(duration2)),
            new BN(SECONDS_IN_DAY).mul(new BN(duration3)),
            new BN(SECONDS_IN_DAY).mul(new BN(duration4)),
          ],
          [new BN(apy1), new BN(apy2), new BN(apy3), new BN(apy4)]
        )
        .accounts({
          vault: vault,
          vaultRef: vaultRef.publicKey.toBase58(),
          vaultTokenAccount: vaultTokenAccount,
          allowedToken: allowedToken,
          feeAccount: feeAccount,
          systemProgram: SystemProgram.programId,
          owner: walletContext.publicKey,
        })
        .signers([])
        .transaction();

      setLoading(false);
      await sendAndConfirmTnx(tx, connection, walletContext);

      const mintInfo = await getMint(connection, allowedToken);

      await depositRewards(program, amount, vault, allowedToken, mintInfo?.decimals, connection, walletContext);

      alert("Pool succesfully created!");
    } catch (error) {
      setLoading(false);
      console.error("Error creating pool:", error);
    }
  };

  return (
    <div className="createPool">
      <h3>Create staking pool</h3>
      <div className="createBody">
        <div className="selectConnect">
          <div className="poolBlocksContainer">
            <div onClick={() => setSelected(1)} className="poolBoxHeader">
              <h3>
                {walletContext.connected ? selectedIcon : selectIcon}
                <span>Connect wallet</span>
              </h3>
              <div className="selectRightSection">
                {walletContext.connected ? (
                  <div className="currentWallet">
                    <img height={24} alt="wallet icon" src={walletIcon} />
                    <span>{formatAddress(walletContext.publicKey)}</span>
                  </div>
                ) : null}
                <button style={{ transform: selected === 1 ? "rotate(0)" : "rotate(180deg)" }}>{arrowIcon}</button>
              </div>
            </div>
            <div className={`selectConnectBody ${selected === 1 ? "open" : ""}`}>
              <WalletMultiButton />
            </div>
          </div>
        </div>
        <div className="selectToken">
          <div className="poolBlocksContainer">
            <div onClick={() => setSelected(2)} className="poolBoxHeader">
              <h3>
                {selectDuration !== 0 ? selectedIcon : selectIcon}
                <span>Select token</span>
              </h3>
              <button style={{ transform: selected === 2 ? "rotate(0)" : "rotate(180deg)" }}>{arrowIcon}</button>
            </div>
            <div className={`selectConnectBody ${selected === 2 ? "open" : ""}`}>
              <div className="selectInputWrapper">
                <select value={token} onChange={handleSelectChange}>
                  <option value="">Select Token</option>
                  {wallTokens?.length > 0
                    ? wallTokens.map((token, index) => (
                        <option value={token.mint} key={index}>
                          {token.mint} : {token.amount}
                        </option>
                      ))
                    : null}
                </select>
                <input onChange={(e) => setAmount(e.target.value)} value={amount} placeholder="Enter amount for pool" />
              </div>
              <button onClick={() => setSelected(3)}>Save</button>
            </div>
          </div>
        </div>
        <div className="selectDuration">
          <div className="poolBlocksContainer">
            <div onClick={() => setSelected(3)} className="poolBoxHeader">
              <h3>
                {selectDuration !== 0 ? selectedIcon : selectIcon}
                <span>Duration and APY</span>
              </h3>
              <button style={{ transform: selected === 3 ? "rotate(0)" : "rotate(180deg)" }}>{arrowIcon}</button>
            </div>
            <div className={`selectConnectBody ${selected === 3 ? "open" : ""}`}>
              <div className="selectInputWrapper">
                <input
                  onChange={(e) => handleSetDurration(e.target.value, setDuration1)}
                  value={duration1}
                  type="number"
                  placeholder="Enter days of lockup option #1"
                />
                <input
                  onChange={(e) => handleSetDurration(e.target.value, setDuration2)}
                  value={duration2}
                  type="number"
                  placeholder="Enter days of lockup option #2"
                />
                <input
                  onChange={(e) => handleSetDurration(e.target.value, setDuration3)}
                  value={duration3}
                  type="number"
                  placeholder="Enter days of lockup option #3"
                />
                <input
                  onChange={(e) => handleSetDurration(e.target.value, setDuration4)}
                  value={duration4}
                  type="number"
                  placeholder="Enter days of lockup option #4"
                />
                <input
                  onChange={(e) => setApy1(e.target.value)}
                  value={apy1}
                  type="number"
                  placeholder="Enter APY for lockup option #1"
                />
                <input
                  onChange={(e) => setApy2(e.target.value)}
                  value={apy2}
                  type="number"
                  placeholder="Enter APY for lockup option #2"
                />
                <input
                  onChange={(e) => setApy3(e.target.value)}
                  value={apy3}
                  type="number"
                  placeholder="Enter APY for lockup option #3"
                />
                <input
                  onChange={(e) => setApy4(e.target.value)}
                  value={apy4}
                  type="number"
                  placeholder="Enter APY for lockup option #4"
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  handlePoolCreate();
                }}
              >
                Create pool {spinner}
              </button>
              <p>
                Total Fees: <span>2.5 SOL</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreatePool;
