import React, { useState } from 'react';
import './faqs.sass'

const arrowIcon = (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9.5L7 2.5L13 9.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

function Faqs() {
  const [openSections, setOpenSections] = useState([false, false, false]);

  const handleOpen = (index) => {
    const newOpenSections = [...openSections];
    newOpenSections[index] = !newOpenSections[index];
    setOpenSections(newOpenSections);
    console.log(`Section ${index + 1} is now:`, newOpenSections[index] ? 'OPEN' : 'CLOSED');
  };

  return (
    <div className='faqsContainer'>
      <h3>FAQ</h3>
      <div className='faqsWrapper'>
        {['How much does it cost to create a token?', 'Which chains do you support?', 'Can I create testnet token?'].map((question, index) => (
          <div className="faqItem" key={index}>
            <div className="faqQuestion" onClick={() => handleOpen(index)}>
              <span>{question}</span>
              <span className={`arrow ${openSections[index] ? 'open' : ''}`}>{arrowIcon}</span>
            </div>
            <div className={`faqAnswer ${openSections[index] ? 'open' : ''}`}>
              {index === 0 && <p>We have lowest fee in the market 0.05 SOL for creating simple token on Solana in 1 minute.</p>}
              {index === 1 && <p>Right now we support Solana network.</p>}
              {index === 2 && <p>For now it works only on mainnet but soon it will be possible to create tokens on devnet</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faqs;
