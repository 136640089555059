import React, { useEffect, useState } from "react";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import { BN } from "@coral-xyz/anchor";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress, getMint } from "@solana/spl-token";
import { ThreeDots } from "react-loader-spinner";
import useStakingProgram from "../../../../hooks/useStakingProgram";
import { feeAccount, programId } from "../../../../utils/config";
import {
  fetchStakingAccounts,
  fetchVaultData,
  getStakingAccount,
  getTokenBalance,
  sendAndConfirmTnx,
} from "../../../../utils/helpers";
import useAnchorProvider from "../../../../hooks/useAnchorProvider";

const selectedIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#DD8502" />
    <path d="M7 12.2857L10.4878 16L18 8" stroke="#FFF8C6" strokeLinecap="round" />
  </svg>
);

const selectIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#EBEBEB" />
  </svg>
);

const arrowIcon = (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9.5L7 2.5L13 9.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

const StakeToken = (props) => {
  const vault = new PublicKey(props.pool.vault);
  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const walletContext = useWallet();
  const provider = useAnchorProvider();
  const program = useStakingProgram();

  const [loading, setLoading] = useState(false);
  const [selectDuration, setSelectDuration] = useState(0);
  const [tokenName, setTokenName] = useState("Unknown Token");
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [selected, setSelected] = useState(1);
  const [tokenMint, setTokenMint] = useState();
  const [vaultData, setVaultData] = useState();
  const [decimals, setDecimals] = useState(6);
  const [index, setIndex] = useState(0);

  const spinner = (
    <ThreeDots
      visible={loading ? true : false}
      height="16"
      width="40"
      color="#7A3B0D"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );

  const duration = vaultData
    ? [
        {
          value: 1,
          apy: vaultData.lockupMultiplier1,
          months: Math.floor(vaultData.lockupDuration1 / 60 / 60 / 24),
        },
        {
          value: 2,
          apy: vaultData.lockupMultiplier2,
          months: Math.floor(vaultData.lockupDuration2 / 60 / 60 / 24),
        },
        {
          value: 3,
          apy: vaultData.lockupMultiplier3,
          months: Math.floor(vaultData.lockupDuration3 / 60 / 60 / 24),
        },
        {
          value: 4,
          apy: vaultData.lockupMultiplier4,
          months: Math.floor(vaultData.lockupDuration4 / 60 / 60 / 24),
        },
      ]
    : [];

  useEffect(() => {
    if (props?.pool?.name) {
      setTokenName(props.pool.name);
    }

    if (props?.pool?.AllowedToken && props?.pool?.decimals) {
      setVaultData(props.pool);
      setTokenMint(new PublicKey(props.pool.AllowedToken));
      setDecimals(props.pool.decimals);
      return;
    }

    if (!connection) {
      return;
    }

    const fetchPublicData = async () => {
      const vaultData = await fetchVaultData(vault, connection);
      const mintInfo = await getMint(connection, new PublicKey(vaultData.AllowedToken));
      setVaultData(vaultData);
      setTokenMint(new PublicKey(vaultData.AllowedToken));
      setDecimals(mintInfo.decimals);
    };

    fetchPublicData().catch(console.error);
  }, [connection]);

  useEffect(() => {
    if (!provider?.wallet || !vaultData) {
      return;
    }

    const fetchPrivateData = async () => {
      const owner = provider.wallet.publicKey;
      const stakingData = await fetchStakingAccounts(programId, owner, vault, connection);
      const tokenBalance = await getTokenBalance(provider, new PublicKey(vaultData.AllowedToken));
      setIndex(stakingData.length);
      setBalance(tokenBalance);
    };

    fetchPrivateData().catch(console.error);
  }, [provider?.connection, provider?.wallet?.publicKey, vaultData]);

  const handleStake = async () => {
    setLoading(true);
    try {
      if (!wallet) throw new WalletNotConnectedError();
      if (!tokenMint) return;
      const userStakingAccount = await getStakingAccount(wallet, index, vault, programId);
      const userTokenAccount = await getAssociatedTokenAddress(tokenMint, provider.wallet.publicKey);
      const [vaultTokenAccount] = PublicKey.findProgramAddressSync(
        [vault.toBuffer(), Buffer.from("vault_tokens")],
        programId
      );

      let tokenAmount = new BN(Math.floor(Number(amount) * Math.pow(10, Number(decimals))));

      const tx = await program.methods
        .initializeAndStake(index, vault, tokenAmount, new BN(selectDuration))
        .accounts({
          stakingAccount: userStakingAccount,
          userTokenAccount: userTokenAccount,
          vaultTokenAccount: vaultTokenAccount,
          vault: vault,
          tokenMint: tokenMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          owner: provider.wallet.publicKey,
          feeAccount: feeAccount,
          systemProgram: SystemProgram.programId,
        })
        .signers([])
        .transaction();

      await sendAndConfirmTnx(tx, connection, walletContext);

      setIndex(index+1);
      setLoading(false);
      setAmount(0);
      alert("Successfully staked your tokens!");
    } catch (error) {
      setLoading(false);
      console.log("Error staking tokens:", error);
      alert("Error staking tokens:", error);
    }
  };

  const handleSelectApy = (v) => {
    setSelectDuration(v);
  };

  return (
    <div className="stakeTokenLeft">
      <h3>Stake ${tokenName}</h3>
      <div className="stakingInnerWrap">
        <div className="stakingRow">
          <div className="stakingBlocksContainer2">
            <div onClick={() => setSelected(1)} className="stakingBoxHeader">
              <h3>
                {selectDuration !== 0 ? selectedIcon : selectIcon}
                <span>Select duration</span>
              </h3>
              <button style={{ transform: selected === 1 ? "rotate(0)" : "rotate(180deg)" }}>{arrowIcon}</button>
            </div>
            <div className={`selectWrapper ${selected === 1 ? "open" : ""}`}>
              {duration.map((d, index) => (
                <button
                  className={d.value === selectDuration ? "active stakingInner2" : "stakingInner2"}
                  onClick={() => {
                    handleSelectApy(d.value);
                  }}
                  key={index}
                >
                  <span>{d.apy}% APY</span>
                  <span className="noteText">{d.months} days</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="stakingBlocksContainer2">
        <div className="stakingInnerWrap2">
          <div onClick={() => setSelected(2)} className="stakingBoxHeader">
            <h3>
              {amount !== 0 ? selectedIcon : selectIcon}
              <span>Enter amount</span>
            </h3>
            <button style={{ transform: selected === 2 ? "rotate(0)" : "rotate(180deg)" }}>{arrowIcon}</button>
          </div>
          <div className={`stakingInput ${selected === 2 ? "open" : ""}`}>
            <div className="stakingInputWrap">
              <input
                type="number"
                placeholder="Enter amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <button>Max</button>
            </div>
            <p>
              Available balance:{" "}
              <span>
                {balance.toFixed(2) || "xxxx"} ${tokenName}
              </span>
            </p>
            <button className="buttonStake" onClick={handleStake}>
              {loading ? "Staking" : "Stake"} {spinner}
            </button>
            <div className="feeNotice">
              Total Fee: <span>0.05 SOL</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StakeToken;
