
import './header.sass'
import { NavLink } from "react-router-dom";
import logo from '../../images/logo.png'
import solana from '../../images/solana.png'
import {
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui'
import { useState } from 'react';
import '../../styles/wallet.css'

const burger = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="8" fill="#FAB10D"/>
<rect x="13" y="15" width="14" height="2" rx="1" fill="#FFFCEB"/>
<rect x="13" y="19" width="14" height="2" rx="1" fill="#FFFCEB"/>
<rect x="13" y="23" width="14" height="2" rx="1" fill="#FFFCEB"/>
</svg>
const close = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="40" height="40" rx="8" fill="#FAB10D"/>
<rect x="25.25" y="27" width="17.3241" height="2.47487" rx="1.23744" transform="rotate(-135 25.25 27)" fill="#FFFCEB"/>
<rect x="13" y="25.25" width="17.3241" height="2.47487" rx="1.23744" transform="rotate(-45 13 25.25)" fill="#FFFCEB"/>
</svg>



function Header({setMobileOpen, mobileOpen}) {

  const handleOpen = () => {
    setMobileOpen(mobileOpen => !mobileOpen)
  }

    return (
      <div className="header">
        <div className='headerLeft'>
          <button onClick={() => handleOpen()} className='menuBurger'>{mobileOpen ? close : burger}</button>
          <a
            href="https://godlenfish.com/"
            className='logo'
            ><img height={48} src={logo} alt="godlenfish logo" />
          </a>
        </div>
        <div className='headerRight'>
          <WalletMultiButton />
        </div>
      </div>
    );
  }
  
  export default Header;
  