import { useMemo } from "react";
import useProvider from "./useAnchorProvider";
import { IDL } from "../idl/staking";
import { programId } from "../utils/config";
import { Program } from "@coral-xyz/anchor";

const useStakingProgram = () => {
  const provider = useProvider();

  const program = useMemo(() => {
    if (!provider) {
      return null;
    }
    return new Program(IDL, programId, provider);
  }, [provider]);

  return program;
};

export default useStakingProgram;
