import "./stakingPools.sass";
import Popup from "reactjs-popup";
import coin from "../../../../images/logo.svg";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import EditPool from "./EditPool";
import { getMint } from "@solana/spl-token";

const closeIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.39106 11.7203C-0.130354 12.2417 -0.130353 13.0871 0.39106 13.6085C0.912474 14.1299 1.75785 14.1299 2.27927 13.6085L6.99964 8.88813L11.7206 13.6091C12.242 14.1305 13.0874 14.1305 13.6088 13.6091C14.1303 13.0877 14.1303 12.2423 13.6088 11.7209L8.88785 6.99992L13.6085 2.27927C14.1299 1.75785 14.1299 0.912473 13.6085 0.39106C13.0871 -0.130354 12.2417 -0.130353 11.7203 0.391061L6.99964 5.11172L2.2796 0.391675C1.75819 -0.129738 0.912809 -0.129738 0.391396 0.391676C-0.130018 0.913089 -0.130017 1.75847 0.391397 2.27988L5.11144 6.99992L0.39106 11.7203Z"
      fill="#020304"
    />
  </svg>
);

const Pool = ({ poolInfo, setShowStakeTokens, setStakingPool }) => {
  const { connection } = useConnection();
  const { publicKey } = useWallet();

  const [mintInfo, setMintInfo] = useState();
  const [showEditPool, setShowEditPool] = useState(false);
  const closeModalCreatePool = () => setShowEditPool(false);

  useEffect(() => {
    if (!connection) {
      return;
    }

    const fetchData = async () => {
      const mintAddress = new PublicKey(poolInfo.AllowedToken);
      const mintInfo = await getMint(connection, mintAddress);
      setMintInfo(mintInfo);
    };

    fetchData().catch(console.error);
  }, [connection]);

  return (
    <div className="poolWrapper">
      <div className="pool">
        <img src={poolInfo?.uri ?? coin} width={64} height={64} alt="pool icon" />
        <span className="onChain">On chain</span>
        <h4>{poolInfo?.name ?? "Unknown Token"}</h4>
        <span className="poolApy">up to APY {poolInfo?.lockupMultiplier4}%</span>
        <button
          onClick={() => {
            setShowStakeTokens(true);
            setStakingPool({ ...poolInfo, ...mintInfo });
          }}
        >
          Stake now
        </button>
      </div>

      {poolInfo.owner === publicKey?.toString() ? (
        <button className="editPool" onClick={() => setShowEditPool(true)}>
          Edit pool
        </button>
      ) : null}
      <Popup className="modalWrapper" open={showEditPool} closeOnDocumentClick onClose={closeModalCreatePool}>
        <div className="modal">
          <button className="close" onClick={closeModalCreatePool}>
            {closeIcon}
          </button>
          <EditPool poolInfo={poolInfo} mintInfo={mintInfo} />
        </div>
      </Popup>
    </div>
  );
};

export default Pool;
