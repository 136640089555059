import React, { useState } from 'react';

const arrowIcon = (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9.5L7 2.5L13 9.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

function Faqs() {
  const [openSections, setOpenSections] = useState([false, false, false]);

  const handleOpen = (index) => {
    const newOpenSections = [...openSections];
    newOpenSections[index] = !newOpenSections[index];
    setOpenSections(newOpenSections);
    console.log(`Section ${index + 1} is now:`, newOpenSections[index] ? 'OPEN' : 'CLOSED');
  };

  return (
    <div className='faqsContainer'>
      <h3>FAQ</h3>
      <div className='faqsWrapper'>
        {['When i will get staking rewards? ', 'When i can unstake?', 'What are $GODLEN staking benefits?', 'What is staking pool?'].map((question, index) => (
          <div className="faqItem" key={index}>
            <div className="faqQuestion" onClick={() => handleOpen(index)}>
              <span>{question}</span>
              <span className={`arrow ${openSections[index] ? 'open' : ''}`}>{arrowIcon}</span>
            </div>
            <div className={`faqAnswer ${openSections[index] ? 'open' : ''}`}>
              {index === 0 && <p>You will be able to claim and withdraw $GOGLEN as rewards after staking time finishes. </p>}
              {index === 1 && <p>You will be able to unstake and withdraw your staked $GODLENs after staking time finishes. </p>}
              {index === 2 && <p>There are a lot of benefits of staking $GODLENs. You will get huge APY from rewards pools. Moreover you will get huge discounts or even free usage of GODLEN utilities ecosystem. </p>}
              {index === 3 && <p>Staking pool can be created by anyone. You just simply add any tokens on Solana as rewards and share them to GODLEN army. You can engage any token community and spread awareness about any of token on Solana.</p>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faqs;
