// src/ImageUpload.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner'

const uploadIcon = <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1886_1502)">
<mask id="mask0_1886_1502" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
<path d="M16.125 0H0.125V16H16.125V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_1886_1502)">
<path d="M5.29914 4.43382L7.15304 2.57925L7.16704 11.6668C7.16704 12.2191 7.61474 12.6668 8.16704 12.6668C8.71929 12.6668 9.16699 12.2191 9.16699 11.6668L9.15299 2.59059L10.9962 4.43385C11.3799 4.83109 12.0129 4.84209 12.4102 4.45842C12.8074 4.07475 12.8184 3.44171 12.4347 3.04448C12.4267 3.03613 12.4185 3.02795 12.4102 3.01992L10.2689 0.87866C9.09744 -0.292882 7.19799 -0.292882 6.02644 0.87863L3.8852 3.01988C3.50153 3.41712 3.51253 4.05015 3.90976 4.43382C4.29728 4.80808 4.91163 4.80808 5.29914 4.43382Z" fill="#260900"/>
<path d="M15.1253 9.66699C14.5731 9.66699 14.1254 10.1147 14.1254 10.6669V13.7274C14.125 13.8779 14.0031 13.9997 13.8527 14.0001H2.39853C2.2481 13.9997 2.12623 13.8779 2.12589 13.7274V10.6669C2.12589 10.1147 1.67819 9.66699 1.12593 9.66699C0.573678 9.66699 0.125977 10.1147 0.125977 10.6669V13.7274C0.127445 14.982 1.14406 15.9985 2.39853 16H13.8527C15.1072 15.9985 16.1238 14.9819 16.1252 13.7274V10.6669C16.1253 10.1147 15.6776 9.66699 15.1253 9.66699Z" fill="#260900"/>
</g>
</g>
<defs>
<clipPath id="clip0_1886_1502">
<rect width="16.5" height="16" fill="white"/>
</clipPath>
</defs>
</svg>


const JWT = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJhYTAwM2M1Yi0xZDdmLTQ3ZTEtYjRhMC05ODI4MzI2NjZmMTUiLCJlbWFpbCI6ImdvZGxlbmZpc2hAcHJvdG9uLm1lIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siaWQiOiJGUkExIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9LHsiaWQiOiJOWUMxIiwiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjF9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6ImI0NjZmZTlmNWQ1YWNiNjg1MzM2Iiwic2NvcGVkS2V5U2VjcmV0IjoiZGZhNWNmMmFkNTE3MWY3ZDdjYWJiMmY2NzcyMmVjMWJkNzNmN2EyNzMzNTJiZWNhYzRiN2EyOTYxMGIzZmQ4ZiIsImlhdCI6MTcxOTQ3NTgxOH0.CgYxGG3JrEizrD2hWmg6FFH9JCIj8RmWlK-T2UIxFcQ';

const ImageUpload = ({ file, setFile, setTokenUrl }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMesssage] = useState('');
  const spinner = <ThreeDots
  height="16"
  width="40"
  color="#000"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    if (file) {
      handleSubmit();
    }
  }, [file]);

  const handleSubmit = async () => {
    if (file) {
      setLoading(true)
      console.log('Started');
      const formData = new FormData();
      formData.append('file', file);

      const pinataMetadata = JSON.stringify({
        name: 'File name',
      });
      formData.append('pinataMetadata', pinataMetadata);

      const pinataOptions = JSON.stringify({
        cidVersion: 0,
      });
      formData.append('pinataOptions', pinataOptions);

      try {
        const res = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
          maxBodyLength: 'Infinity',
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
            'Authorization': `Bearer ${JWT}`,
          },
        });
        console.log('File uploaded successfully!', res.data.IpfsHash);
        setTokenUrl(`https://gateway.pinata.cloud/ipfs/${res.data.IpfsHash}`)
        setMesssage('Completed')
        setLoading(false)
      } catch (error) {
        setLoading(false)
        setMesssage('Failed try again')
        console.error('Error uploading the file:', error);
        return
      }
    } else {
      console.log('no file');
    }
  };

  return (
    <div className='imageUpload'>
      <form onSubmit={(e) => e.preventDefault()}>
        <input type="file" onChange={handleFileChange} />
        <div className='innerInputReplacement'>{uploadIcon} <span>Upload token image</span></div>
        {loading ? spinner : <span>{message}</span>}
      </form>
    </div>
  );
};

export default ImageUpload;
