import { TOKEN_PROGRAM_ID, getAccount, getAssociatedTokenAddressSync, getMint } from "@solana/spl-token";
import { ComputeBudgetProgram, PublicKey } from "@solana/web3.js";
import { BN } from "bn.js";
import { Buffer } from "buffer";
import { SECONDS_IN_YEAR, godlenVault } from "./config";
import { Metaplex } from "@metaplex-foundation/js";

export async function fetchStakingAccounts(programId, owner, vault, connection) {
  const accounts = await connection.getProgramAccounts(new PublicKey(programId), {
    filters: [
      {
        memcmp: {
          offset: 8,
          bytes: owner.toBase58(),
        },
      },
      {
        memcmp: {
          offset: 40,
          bytes: vault.toBase58(),
        },
      },
    ],
  });

  return accounts.map(({ pubkey, account }) => {
    const stakingAccountInfo = deserializeStakingAccount(account.data);
    return {
      accountId: pubkey.toString(),
      ...stakingAccountInfo,
    };
  });
}

export async function fetchAllVaults(connection, programId, hideGodlen = true) {
  const dataSizeFilter = {
    dataSize: 444,
  };
  const accounts = await connection.getProgramAccounts(programId, {
    filters: [dataSizeFilter],
  });

  const vaults = accounts
    .filter((item) => {
      if (hideGodlen) {
        return item.pubkey.toString() !== godlenVault.toString();
      } else {
        return true;
      }
    })
    .map(({ pubkey, account }) => {
      const vaultAccountInfo = deserializeVaultAccount(account.data);

      return {
        vault: pubkey.toString(),
        ...vaultAccountInfo,
      };
    });
  return vaults;
}

export async function fetchAllVaultsWithMeta(connection, programId, hideGodlen = true) {
  const metaplex = Metaplex.make(connection);
  const vaults = await fetchAllVaults(connection, programId, hideGodlen);

  const promises = vaults.map(async (item) => {
    try {
      const metadata = await metaplex.nfts().findByMint({ mintAddress: new PublicKey(item.AllowedToken) });
      return { ...item, ...metadata };
    } catch (e) {
      return { ...item, name: "Unknown Token" };
    }
  });

  const results = await Promise.all(promises);

  return results;
}

export async function fetchVaultData(vaultAddress, connection) {
  const accountInfo = await connection.getAccountInfo(new PublicKey(vaultAddress));
  if (accountInfo === null) {
    throw new Error("Vault account not found");
  }

  const vaultData = deserializeVaultAccount(accountInfo.data);
  return vaultData;
}

function deserializeStakingAccount(data) {
  let offset = 8;
  const owner = new PublicKey(data.slice(offset, offset + 32));
  offset += 32;
  const vault = new PublicKey(data.slice(offset, offset + 32));
  offset += 32;
  const stakedAmount = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const unlockTimestamp = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupOption = new BN(data.slice(offset, offset + 1), "le", "bn");
  offset += 1;
  const timestamp = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const rewardsLength = new BN(data.slice(offset, offset + 4), "le", "bn").toNumber();
  offset += 4;
  const rewards = [];
  for (let i = 0; i < rewardsLength; i++) {
    const rewardTokenMint = new PublicKey(data.slice(offset, offset + 32));
    offset += 32;
    const amount = new BN(data.slice(offset, offset + 8), "le", "bn");
    offset += 8;
    rewards.push({
      rewardTokenMint: rewardTokenMint.toString(),
      amount: amount.toString(10),
    });
  }

  return {
    owner: owner.toString(),
    vault: vault.toString(),
    stakedAmount: stakedAmount.toString(10),
    unlockTimestamp: unlockTimestamp.toString(10),
    lockupOption: lockupOption.toString(10),
    timestamp: timestamp.toString(),
    rewards,
  };
}

function deserializeVaultAccount(data) {
  let offset = 8;
  const owner = new PublicKey(data.slice(offset, offset + 32));
  offset += 32;
  const allowedToken = new PublicKey(data.slice(offset, offset + 32));
  offset += 32;
  const vaultTokenAccount = new PublicKey(data.slice(offset, offset + 32));
  offset += 32;
  const lockupDuration1 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupDuration2 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupDuration3 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupDuration4 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupMultiplier1 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupMultiplier2 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupMultiplier3 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 8;
  const lockupMultiplier4 = new BN(data.slice(offset, offset + 8), "le", "bn");
  offset += 40;
  const rewardsLength = new BN(data.slice(offset, offset + 4), "le", "bn").toNumber();
  offset += 4;
  const rewards = [];
  for (let i = 0; i < rewardsLength; i++) {
    const rewardTokenMint = new PublicKey(data.slice(offset, offset + 32));
    offset += 32;
    const schedule = new BN(data.slice(offset, offset + 8), "le", "bn");
    offset += 8;
    const timestamp = new BN(data.slice(offset, offset + 8), "le", "bn");
    offset += 8;
    rewards.push({
      rewardTokenMint: rewardTokenMint.toString(),
      schedule: schedule.toString(10),
      timestamp: timestamp,
    });
  }

  return {
    owner: owner.toString(),
    AllowedToken: allowedToken.toString(),
    vaultTokenAccount: vaultTokenAccount.toString(),
    lockupDuration1: lockupDuration1.toString(10),
    lockupDuration2: lockupDuration2.toString(10),
    lockupDuration3: lockupDuration3.toString(10),
    lockupDuration4: lockupDuration4.toString(10),
    lockupMultiplier1: lockupMultiplier1.toString(10),
    lockupMultiplier2: lockupMultiplier2.toString(10),
    lockupMultiplier3: lockupMultiplier3.toString(10),
    lockupMultiplier4: lockupMultiplier4.toString(10),
    rewards,
  };
}

export async function getStakingAccount(user, index, vault, staking_program_id) {
  const [userStakingAccount] = PublicKey.findProgramAddressSync(
    [Buffer.from("staking_account"), user.publicKey.toBuffer(), vault.toBuffer(), [index]],
    staking_program_id
  );
  return userStakingAccount;
}

export async function getTokenBalance(provider, tokenMintAddress) {
  const tokenAccount = getAssociatedTokenAddressSync(tokenMintAddress, provider.wallet.publicKey);
  const info = await getAccount(provider.connection, tokenAccount);
  const amount = Number(info.amount);
  const mint = await getMint(provider.connection, info.mint);
  const balance = amount / 10 ** mint.decimals;
  return balance;
}

export function calculateRewards(stakeData, vaultData, decimals) {
  const currentTime = new Date() / 1000;
  const timeElapsed = currentTime - stakeData.timestamp;
  const scaledSchedule = Math.pow(10, decimals) * 1000000000;
  const rewardPerSecond = scaledSchedule / SECONDS_IN_YEAR;
  let userReward =
    (rewardPerSecond * timeElapsed * stakeData.stakedAmount) /
    Math.pow(10, decimals) /
    Math.pow(10, decimals) /
    1000000000;

  const multiplier = getMultiplier(stakeData.lockupOption, vaultData);
  userReward = (userReward * multiplier) / 100;
  let decimalFactor = decimals;
  if (decimalFactor < 2) {
    decimalFactor = 2;
  }

  return userReward.toFixed(decimalFactor);
}

export const getMultiplier = (lockupOption, vaultData) => {
  const option = parseInt(lockupOption, 10);
  switch (option) {
    case 1:
      return vaultData.lockupMultiplier1;
    case 2:
      return vaultData.lockupMultiplier2;
    case 3:
      return vaultData.lockupMultiplier3;
    case 4:
      return vaultData.lockupMultiplier4;
    default:
      return 0;
  }
};

export function fromTokenAmount(amount, decimals = 6) {
  return amount / Math.pow(10, decimals);
}

export function toTokenAmount(amount, decimals = 6) {
  return amount * Math.pow(10, decimals);
}

export async function getTokenMetadata(connection, tokenMint) {
  return await connection.getParsedAccountInfo(new PublicKey(tokenMint));
}

export const formatAddress = (address) => {
  if (!address) return "";
  return `${address.toString().slice(0, 4)}...${address.toString().slice(-4)}`;
};

export const sendAndConfirmTnx = async (tx, connection, walletContext, priorityFee = 0) => {
  const { blockhash } = await connection.getLatestBlockhash();
  tx.recentBlockhash = blockhash;
  tx.feePayer = walletContext.publicKey;

  if (priorityFee > 0) {
    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
      microLamports: priorityFee,
    });
    tx.add(addPriorityFee);
  }

  const signature = await walletContext.sendTransaction(tx, connection);

  const confirmation = await connection.confirmTransaction(
    {
      blockhash: blockhash,
      signature: signature,
    },
    "confirmed"
  );
  if (confirmation.value.err) {
    console.error("Transaction failed:", confirmation.value.err);
    throw new Error("Transaction failed:", confirmation.value.err.toString());
  } else {
    console.log("Transaction confirmed:", signature);
  }
  return confirmation;
};

export async function getWalletTokens(connection, walletAddress) {
  const publicKey = new PublicKey(walletAddress);
  const tokenAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID,
  });

  const tokens = tokenAccounts.value.map((tokenAccountInfo) => {
      const tokenAccount = tokenAccountInfo.account.data.parsed.info;
      return {mint: tokenAccount.mint, amount: tokenAccount.tokenAmount.uiAmount};
  });
  return tokens;
}