import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress } from "@solana/spl-token";
import { feeAccount, programId } from "./config";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import { BN } from "bn.js";
import { sendAndConfirmTnx } from "./helpers";

export async function depositRewards(program, amount, vaultAddress, vaultToken, rewardDecimals, connection, walletContext) {
  const vault = new PublicKey(vaultAddress);
  const tokenMint = new PublicKey(vaultToken);
  const rewardTokenAccount = await getAssociatedTokenAddress(tokenMint, walletContext.publicKey);
  const [vaultRewardTokenAccount] = PublicKey.findProgramAddressSync(
    [Buffer.from("spl_rewards"), vault.toBuffer(), tokenMint.toBuffer()],
    programId
  );

  const tx = await program.methods
    .addRewards(new BN(amount * Math.pow(10, rewardDecimals)))
    .accounts({
      vault: vault,
      rewardTokenAccount: rewardTokenAccount,
      vaultRewardTokenAccount: vaultRewardTokenAccount,
      rewardTokenMint: tokenMint,
      tokenProgram: TOKEN_PROGRAM_ID,
      vaultOwner: walletContext.publicKey,
      feeAccount: feeAccount,
      systemProgram: SystemProgram.programId,
    })
    .signers([])
    .transaction();

  const confimation = await sendAndConfirmTnx(tx, connection, walletContext);

  return confimation;
}
