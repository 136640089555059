
import './notifications.sass'

const closeIcon = <svg width="10" height="10" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.39106 11.7203C-0.130354 12.2417 -0.130353 13.0871 0.39106 13.6085C0.912474 14.1299 1.75785 14.1299 2.27927 13.6085L6.99964 8.88813L11.7206 13.6091C12.242 14.1305 13.0874 14.1305 13.6088 13.6091C14.1303 13.0877 14.1303 12.2423 13.6088 11.7209L8.88785 6.99992L13.6085 2.27927C14.1299 1.75785 14.1299 0.912473 13.6085 0.39106C13.0871 -0.130354 12.2417 -0.130353 11.7203 0.391061L6.99964 5.11172L2.2796 0.391675C1.75819 -0.129738 0.912809 -0.129738 0.391396 0.391676C-0.130018 0.913089 -0.130017 1.75847 0.391397 2.27988L5.11144 6.99992L0.39106 11.7203Z" fill="#020304"></path></svg>
const alertIcon = <svg width="20" height="20" viewBox="0 0 24 24" focusable="false" class="chakra-icon css-qk6lof"><path fill="currentColor" d="M11.983,0a12.206,12.206,0,0,0-8.51,3.653A11.8,11.8,0,0,0,0,12.207,11.779,11.779,0,0,0,11.8,24h.214A12.111,12.111,0,0,0,24,11.791h0A11.766,11.766,0,0,0,11.983,0ZM10.5,16.542a1.476,1.476,0,0,1,1.449-1.53h.027a1.527,1.527,0,0,1,1.523,1.47,1.475,1.475,0,0,1-1.449,1.53h-.027A1.529,1.529,0,0,1,10.5,16.542ZM11,12.5v-6a1,1,0,0,1,2,0v6a1,1,0,1,1-2,0Z"></path></svg>

function Notifications({notifications, setNotifications}) {

  const handleClose = (id) => {
    setNotifications(prevNotifications => prevNotifications.filter(notification => notification.id !== id));
  }

    return (
      <div className="notifications">
        { notifications.map( notification => (
          <div className='notification' key={notification.id}>
            <button onClick={() => handleClose(notification.id)} className='closeButton'>{closeIcon}</button>
            <div className='noticeHeader'>{alertIcon}<h4>Error</h4></div>
            <div className='noticeBody'>
              <p>{notification.text}</p>
            </div>
          </div>
        ))
        }
      </div>
    );
  }
  
  export default Notifications;
  