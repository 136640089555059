import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import "./stakingPools.sass";
import React, { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import useStakingProgram from "../../../../hooks/useStakingProgram";
import { depositRewards } from "../../../../utils/functions";

const selectedIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#DD8502" />
    <path d="M7 12.2857L10.4878 16L18 8" stroke="#FFF8C6" strokeLinecap="round" />
  </svg>
);

const selectIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#EBEBEB" />
  </svg>
);

const arrowIcon = (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 9.5L7 2.5L13 9.5" stroke="black" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

function EditPool(props) {
  const { poolInfo, mintInfo } = props;
  const walletContext = useWallet();
  const { connection } = useConnection();
  const program = useStakingProgram();

  const [selected, setSelected] = useState(1);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  const spinner = (
    <ThreeDots
      visible={loading ? true : false}
      height="16"
      width="40"
      color="#7A3B0D"
      radius="9"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  );

  const handleDepositRewards = async () => {
    await depositRewards(program, amount, poolInfo.vault, poolInfo.AllowedToken, mintInfo?.decimals, connection, walletContext);
    alert('Successfully deposited rewards!')
  };

  return (
    <div className="stakeTokens">
      <h3>Edit pool</h3>
      <div className="createBody">
        <div className="selectConnect">
          <div className="poolBlocksContainer">
            <div onClick={() => setSelected(1)} className="poolBoxHeader">
              <h3>
                {amount !== "" ? selectedIcon : selectIcon}
                <span>Pool rewards</span>
              </h3>
              <button style={{ transform: selected === 1 ? "rotate(0)" : "rotate(180deg)" }}>{arrowIcon}</button>
            </div>
            <div className={`selectConnectBody ${selected === 1 ? "open" : ""}`}>
              <input
                type="number"
                className="inputAmount"
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                placeholder="Enter amount for pool"
              />
              <button onClick={handleDepositRewards}>Deposit {spinner}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditPool;
