import { useAnchorWallet, useConnection, useWallet } from "@solana/wallet-adapter-react";
import "../../stake.sass";
import { AnchorProvider, BN, Program } from "@coral-xyz/anchor";
import { IDL } from "../../../../idl/staking";
import { feeAccount, godlenVault, programId } from "../../../../utils/config";
import { TOKEN_PROGRAM_ID, getAssociatedTokenAddress, getMint } from "@solana/spl-token";
import { useEffect, useState } from "react";
import { PublicKey, SystemProgram } from "@solana/web3.js";
import Countdown from "react-countdown";
import { WalletNotConnectedError } from "@solana/wallet-adapter-base";
import {
  calculateRewards,
  fetchStakingAccounts,
  fetchVaultData,
  fromTokenAmount,
  getMultiplier,
  sendAndConfirmTnx,
} from "../../../../utils/helpers";

const MainToken = () => {
  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const walletContext = useWallet();
  const provider = new AnchorProvider(connection, wallet, {});
  const program = new Program(IDL, programId, provider);
  const vault = godlenVault;

  const [tokenMint, setTokenMint] = useState();
  const [vaultData, setVaultData] = useState();
  const [stakingData, setStakingData] = useState([]);
  const [decimals, setDecimals] = useState(0);
  const currentDate = new Date();

  useEffect(() => {
    if (!provider?.wallet) {
      return;
    }

    const fetchData = async () => {
      const owner = provider.wallet.publicKey;
      const vaultData = await fetchVaultData(vault, connection);
      const mintInfo = await getMint(provider.connection, new PublicKey(vaultData.AllowedToken));
      const stakingData = await fetchStakingAccounts(programId, owner, vault, connection);
      setVaultData(vaultData);
      setStakingData(stakingData);
      setTokenMint(new PublicKey(vaultData.AllowedToken));
      setDecimals(Number(mintInfo.decimals));
    };

    fetchData().catch(console.error);
  }, [provider?.connection, provider?.wallet?.publicKey]);

  const handleUnstake = async (stake) => {
    if (!wallet) throw new WalletNotConnectedError();

    try {
      const userTokenAccount = await getAssociatedTokenAddress(tokenMint, provider.wallet.publicKey);
      const [vaultTokenAccount] = PublicKey.findProgramAddressSync(
        [vault.toBuffer(), Buffer.from("vault_tokens")],
        programId
      );
      const [vaultRewardTokenAccount] = await PublicKey.findProgramAddressSync(
        [Buffer.from("spl_rewards"), vault.toBuffer(), tokenMint.toBuffer()],
        programId
      );

      const tx = await program.methods
        .withdrawTokens(new BN(stake.stakedAmount), true)
        .accounts({
          stakingAccount: stake.accountId,
          userTokenAccount: userTokenAccount,
          vaultTokenAccount: vaultTokenAccount,
          vaultRewardTokenAccount: vaultRewardTokenAccount,
          vault: vault,
          tokenMint: tokenMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          owner: provider.wallet.publicKey,
          feeAccount: feeAccount,
          systemProgram: SystemProgram.programId,
        })
        .signers([])
        .transaction();

        await sendAndConfirmTnx(tx, connection, walletContext)
    } catch (error) {
      console.error("Error unstaking tokens:", error);
    }
  };

  const handleRewardClaim = async (stake, rewardIndex = 0) => {
    if (!wallet) throw new WalletNotConnectedError();
    try {
      const userTokenAccount = await getAssociatedTokenAddress(tokenMint, provider.wallet.publicKey);
      const [vaultTokenAccount] = PublicKey.findProgramAddressSync(
        [Buffer.from("spl_rewards"), vault.toBuffer(), tokenMint.toBuffer()],
        programId
      );

      const tx = await program.methods
        .withdrawTimedRewards(rewardIndex)
        .accounts({
          stakingAccount: stake.accountId,
          userTokenAccount: userTokenAccount,
          vaultTokenAccount: vaultTokenAccount,
          vault: vault,
          tokenMint: tokenMint,
          stakingMint: tokenMint,
          tokenProgram: TOKEN_PROGRAM_ID,
          owner: stake.owner,
          feeAccount: feeAccount,
          systemProgram: SystemProgram.programId,
        })
        .signers([])
        .transaction();

        await sendAndConfirmTnx(tx, connection, walletContext)
    } catch (error) {
      console.error("Error claiming rewards:", error);
    }
  };

  return (
    <div className="myStakesInner">
      <h3>My stakes</h3>
      <p>Staking rewards can be claimed before staking period ends</p>
      <div className="stakesContainer">
        <div className="tableHeading">
          <span>Staking amount</span>
          <span>Stake Date</span>
          <span>Unlock Date</span>
          <span>APY</span>
          <span style={{ minWidth: "150px" }}>Claim rewards</span>
          <span style={{ minWidth: "150px" }}>Unstake</span>
        </div>
        <div className="tableBody">
          {stakingData?.length > 0
            ? stakingData.map((stake, index) => (
                <div className="tableItem" key={index}>
                  <span>{fromTokenAmount(stake.stakedAmount, decimals).toFixed(2)} $GODLEN</span>
                  <span>{new Date(stake.timestamp * 1000).toUTCString()}</span>
                  <span>{new Date(stake.unlockTimestamp * 1000).toUTCString()}</span>
                  <span>{getMultiplier(stake.lockupOption, vaultData)} %</span>
                  <span>
                    <button
                      onClick={() => {
                        handleRewardClaim(stake);
                      }}
                    >
                      {`Claim ${calculateRewards(stake, vaultData, decimals)}`}
                    </button>
                  </span>
                  <button
                    onClick={() => {
                      handleUnstake(stake);
                    }}
                    style={{
                      background: stake.unlockTimestamp < currentDate / 1000 ? "#FCECAE" : "#000",
                      color: stake.unlockTimestamp < currentDate / 1000 ? "#000" : "#FAB10D",
                    }}
                  >
                    {stake.unlockTimestamp < currentDate / 1000 ? (
                      "Unstake"
                    ) : (
                      <Countdown date={stake.unlockTimestamp * 1000} />
                    )}
                  </button>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  );
};

export default MainToken;
