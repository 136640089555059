import { PublicKey } from "@solana/web3.js";

export const SECONDS_IN_YEAR = 31_536_000;
export const SECONDS_IN_DAY = 86_400;

export const solanaCluster = process.env.REACT_APP_SOLANA_CLUSTER;
export const solanaRpcUrl = process.env.REACT_APP_SOLANA_RPC_URL;
export const programId = new PublicKey(process.env.REACT_APP_STAKING_PROGRAM_ID);
export const feeAccount = new PublicKey(process.env.REACT_APP_FEE_ACCOUNT);
export const godlenVault = new PublicKey(process.env.REACT_APP_GODLEN_VAULT);
export const godlenMintAddress = new PublicKey(process.env.REACT_APP_GODLEN_MINT);
