import React from "react";
import "./tabs.sass";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "../../../styles/progress-bar.css";
import godlenDude from "../../../images/godlenfish-coat.png";
import { godlenVault } from "../../../utils/config";
import StakeToken from "./stakingPools/StakeTokens";
import Faqs from "./Faqs";

const StakeGodlen = () => {
  return (
    <div id="staking" className="stakingSection">
      <StakeToken pool={{name:"GODLEN", vault: godlenVault}}/>
      <div className="stakingCol">
        <div className="stakingInnerWrap">
          <div className="stakingRow">
            <div className="stakingBlocksContainer">
              <div className="stakingInnerWrap4">
                <h3>Staking pool (FCFS)</h3>
                <div className="stakingPool">
                  <div className="stakingPoolWrap">
                    <p>Staking pool 7M $GODLENs as rewards  </p>
                    <img
                      style={{ transform: "scaleX(-1)", marginLeft: -30 }}
                      src={godlenDude}
                      width={240}
                      alt="The Godlenfish logo"
                    />
                  </div>
                  <div className="stakingPoolWrapCircle">
                    {/* <CircularProgressbarWithChildren value={0} strokeWidth={10}>
                      <div className="innerCircleText" style={{ fontSize: 24, marginTop: -5 }}>
                        <strong>0%</strong>
                        <span>Pool filled</span>
                      </div>
                    </CircularProgressbarWithChildren> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="stakingOuterWrap">
                <Faqs />
        </div>
      </div>
    </div>
  );
};

export default StakeGodlen;
