
import './footer.sass'
import { NavLink } from "react-router-dom";
import logo from '../../images/logo.png'
import '../../styles/wallet.css'

function Footer() {

    return (
      <div className="footer">
        <div className='footerLeft'>
          <a
            href="https://godlenfish.com/"
            className='logo'
            ><img width={60} src={logo} alt="godlen meme token" />
            <span>GODLENFISH</span>
          </a>
        </div>
        <div className='footerRight'>
          <a href="https://app.gitbook.com/o/LlWESlMC3vJKQS7Rwnuo/s/tw5C6mziapMXOY1AjsAG/">GODLENPAPER</a>
          <a href="https://godlenfish.com/airdrop">AirDrop</a>
          <a href="https://godlenfish.com">WEBSITE</a>
        </div>
      </div>
    );
  }
  
  export default Footer;
  