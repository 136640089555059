import React, { useState } from 'react';
import './stake.sass'
import StakeGodlen from './tabs/StakeGodlen'
import StakingPools from './tabs/stakingPools/StakingPools.js'
import MyStakes from './tabs/myStakes/MyStakes'

function StakePage() {
  const [tab, setTab] = useState(0);
  return (
    <div className="stake">
        <h2>GODLENStake</h2>
        <p>Stake $GODLEN to earn additional rewards</p>
        <ul>
          <li><button className={tab === 0 ? 'active' : null} onClick={() => setTab(0)}>Stake $GODLEN</button></li>
          <li><button className={tab === 1 ? 'active' : null} onClick={() => setTab(1)}>My stakes</button></li>
          <li><button className={tab === 2 ? 'active' : null} onClick={() => setTab(2)}>Staking pools</button></li>
        </ul>
        <div className='stakeTabs'>
          {tab === 1 ? <MyStakes /> : tab === 2 ? <StakingPools /> : <StakeGodlen /> }
        </div>
    </div>
  );
}

export default StakePage;
