import React, { useMemo } from "react";
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react'
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui'
import * as web3 from '@solana/web3.js'
import './App.css';
import './styles/common.sass'
import "@solana/wallet-adapter-react-ui/styles.css";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import StakePage from './pages/stake/Stake';
import Layout from './components/layout/Layout'
import Home from './pages/home/Home'
import LauncherPage from "./pages/launcher/Launcher";
import { solanaRpcUrl } from "./utils/config";

function App({ pageProps }) {
  const wallets = useMemo(() => [], [])
  
  return (
    <ConnectionProvider endpoint={solanaRpcUrl}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <Router {...pageProps}>
            <Routes>
              <Route path="/" element={<Navigate to="/solana" replace />} />
              <Route path="/solana" element={<Layout><Home /></Layout>} />
              <Route path="/solana/launcher" element={<Layout><LauncherPage /></Layout>} />
              <Route path="/solana/godlen/stake" element={<Layout><StakePage /></Layout>} />
            </Routes>
          </Router>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}

export default App;