import React, { useState, useEffect } from 'react';
import Faqs from './Faqs';
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { Keypair, PublicKey, SystemProgram, Transaction, LAMPORTS_PER_SOL } from '@solana/web3.js';
import { MINT_SIZE, TOKEN_PROGRAM_ID, createInitializeMintInstruction, getMinimumBalanceForRentExemptMint, getAssociatedTokenAddress, createAssociatedTokenAccountInstruction, createMintToInstruction, createSetAuthorityInstruction, AuthorityType } from '@solana/spl-token';
import { mplTokenMetadata,  createMetadataAccountV3 } from '@metaplex-foundation/mpl-token-metadata';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters'
import { fromWeb3JsPublicKey} from '@metaplex-foundation/umi-web3js-adapters';

import './launcher.sass'
import '../../styles/progress-bar.css'
import {
  WalletMultiButton
} from '@solana/wallet-adapter-react-ui'
import { ThreeDots } from 'react-loader-spinner'
import { solanaRpcUrl } from '../../utils/config';
import ImageUpload from './ImageUpload';
import uploadJsonToIPFS from './JsonUpload';


const selectedIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="24" height="24" rx="12" fill="#DD8502"/>
<path d="M7 12.2857L10.4878 16L18 8" stroke="#FFF8C6" strokeLinecap="round"/>
</svg>

const selectIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#EBEBEB"/>
</svg>

const arrowIcon = <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 9.5L7 2.5L13 9.5" stroke="black" strokeWidth="2" strokeLinecap="round"/>
</svg>

function LauncherPage({handleNotification}) {
  // States
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [openSections, setOpenSections] = useState([false, false, false]);
  const [file, setFile] = useState(null);
  const [jsonFile, setJsonFile] = useState(null);
  const [toggles, setToggles] = useState([false, false, false]);
  const [tokenName, setTokenName] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [tokenDecrimals, setTokenDecrimals] = useState(6);
  const [tokenSupply, setTokenSupply] = useState(1);
  const [tokenUrl, setTokenUrl] = useState();
  const [tokenDescription, setTokenDescription] = useState('');
  // const [tokenMintAuthority, setTokenMintAuthority] = useState(false);
  const [creationFee, setCreationFee] = useState(0.05);
  const platformFeeWallet = 'Hz8KkmHEDEUNfSxPgi5xxp6381tkvgBzMKXTa367rtBZ'
  const [processText, setProcessText] = useState('Create Token');

  const wallet = useWallet()
  const { connection } = useConnection();

  const walletIcon = wallet.wallet?.adapter?.icon;

  const spinner = <ThreeDots
  height="16"
  width="40"
  color="#7A3B0D"
  radius="9"
  ariaLabel="three-dots-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />

  const sendSol = async () => {
      setLoading(true)
      setProcessText('Transfering service fee')
      // const clusterApiUrl = 'https://solana-mainnet.core.chainstack.com/2cb4b6f620d5bd6151f5950fc2ff4f8e' // Mainet cluster URL

      // const connection = new Connection(clusterApiUrl)
      console.log('Logging connection: ', connection)
      const walletAccountInfo = await connection.getAccountInfo(wallet.publicKey)
      console.log('Logging connection: ', walletAccountInfo)
      
      const receiverPublicKeyInstance = new PublicKey(platformFeeWallet)
      const transaction = new Transaction().add(
          SystemProgram.transfer({
          fromPubkey: wallet.publicKey,
          toPubkey: receiverPublicKeyInstance,
          lamports: LAMPORTS_PER_SOL * creationFee
        })
      )
      try {
        const latestBlockhash = await connection.getLatestBlockhash()
        transaction.feePayer = wallet.publicKey
        transaction.recentBlockhash = latestBlockhash.blockhash
        const signature = await wallet.sendTransaction(transaction, connection)
        console.log('signature: ', signature)
        createToken()
      } catch (e) {
        setProcessText('Create token')
        setLoading(false)
        console.log('Did not received signature', e)
        alert(e)
      }
  }

  const createToken = async () => {
    setLoading(true)
    setProcessText('Creating token')
    const lamports = await getMinimumBalanceForRentExemptMint(connection);
    const mintKeypair = Keypair.generate();
    console.log('Keypair in create token: ', mintKeypair.publicKey)
    const tokenATA = await getAssociatedTokenAddress(mintKeypair.publicKey, wallet.publicKey);

    const createNewTokenTransaction = new Transaction().add(
      SystemProgram.createAccount({
          fromPubkey: wallet.publicKey,
          newAccountPubkey: mintKeypair.publicKey,
          space: MINT_SIZE,
          lamports: lamports,
          programId: TOKEN_PROGRAM_ID,
      }),
      createInitializeMintInstruction(
        mintKeypair.publicKey, 
        tokenDecrimals, 
        wallet.publicKey, 
        wallet.publicKey, 
        TOKEN_PROGRAM_ID),
      createAssociatedTokenAccountInstruction(
        wallet.publicKey,
        tokenATA,
        wallet.publicKey,
        mintKeypair.publicKey,
      ),
      createMintToInstruction(
        mintKeypair.publicKey,
        tokenATA,
        wallet.publicKey,
        tokenSupply * Math.pow(10, tokenDecrimals),
        {
          commitment : "finalized"  //Finish initialazation of the mint first
        }
      )
    );
    // Send the transaction to create the new token
    const createTokenTxid = await wallet.sendTransaction(createNewTokenTransaction, connection, { signers: [mintKeypair] });
   
    console.log('Create Token Transaction ID:', createTokenTxid);

    // Wait for confirmation of the transaction
    await connection.confirmTransaction(createTokenTxid, 'finalized').then(() => {
      createMetadata(mintKeypair);
    });
};


  const createMetadata = async (mintKeypair) => {
    setProcessText('Uploading metadata')
    console.log('Keypair in create metadata: ', mintKeypair.PublicKey)
    try {
      setLoading(true)
      const mintAddress = mintKeypair.publicKey.toString()
      console.log('Passed: ', mintAddress); // Add this log
      const mint = new PublicKey(mintAddress);
      const umi = createUmi(solanaRpcUrl);
      umi.use(mplTokenMetadata())
         .use(walletAdapterIdentity(wallet))
  
      // const signer = generateSigner(umi);
  
      const ourMetadata = {
        name: tokenName,
        symbol: tokenSymbol,
        uri: jsonFile,
      };
  
      const onChainData = {
        ...ourMetadata,
        sellerFeeBasisPoints: 0,
        creators: null,
        collection: null,
        uses: null,
      };
  
      const mintPublicKey = fromWeb3JsPublicKey(mint);
  
      const accounts = {
        mint: mintPublicKey,
        mintAuthority: wallet.publicKey,
      };
  
      const data = {
        isMutable: true,
        collectionDetails: null,
        data: onChainData,
      };
  
      const txid = await createMetadataAccountV3(umi, { ...accounts, ...data }).sendAndConfirm(umi);

      // Disabling auths

    if(toggles[1] && toggles[2]) {
            // Now disable the mint authority
    const disableMintingTransaction = new Transaction().add(
      createSetAuthorityInstruction(
        mintKeypair.publicKey,
        wallet.publicKey,
        AuthorityType.MintTokens,
        null
      ),
      createSetAuthorityInstruction(
        mintKeypair.publicKey,
        wallet.publicKey,
        AuthorityType.FreezeAccount,
        null
      )
    );
      const disableMintingSignature = await wallet.sendTransaction(disableMintingTransaction, connection, {
        signers: [],
      });
      console.log('Freeze and Mint revoked', disableMintingSignature)
    }

    if(toggles[1] && !toggles[2]) {
      const disableMintingTransaction = new Transaction().add(
        createSetAuthorityInstruction(
          mintKeypair.publicKey,
          wallet.publicKey,
          AuthorityType.FreezeAccount,
          null
        )
      );
      const disableMintingSignature = await wallet.sendTransaction(disableMintingTransaction, connection, {
        signers: [],
      });
      console.log('Freeze and Mint revoked', disableMintingSignature)
    }

    if(!toggles[1] && toggles[2]) {
      const disableMintingTransaction = new Transaction().add(
        createSetAuthorityInstruction(
          mintKeypair.publicKey,
          wallet.publicKey,
          AuthorityType.MintTokens,
          null
        )
      );
      const disableMintingSignature = await wallet.sendTransaction(disableMintingTransaction, connection, {
        signers: [],
      });
      console.log('Freeze and Mint revoked', disableMintingSignature)
    }

    if(!toggles[1] && !toggles[2]) {
      console.log('No features selected')
    }



      setLoading(false)
      console.log('Transaction ID:', txid);
      setProcessText('Create token')
      alert('Token successfully created!')
    } catch (error) {
      setProcessText('Create token')
      alert('Error creating metadata:', error)
      setLoading(false)
      console.error('Error creating metadata:', error);
    }
  };

  

  const handleToggle = (index) => {
    const newToggles = [...toggles];
    newToggles[index] = !newToggles[index];
    setToggles(newToggles);
    const baseFee = 0.05;
    const additionalFee = newToggles.filter(isToggled => isToggled).length * 0.05;
    const newFee = baseFee + additionalFee;
    setCreationFee(newFee);
  
    // Log the current state of the toggle and the creation fee
    console.log(`Toggle ${index + 1} is now:`, newToggles[index] ? 'ON' : 'OFF');
    console.log('Current creation fee:', newFee.toFixed(2));
  };
  
  
  

  const handleOpen = (index) => {
    console.log('Got index: ', index)
    const newOpenSections = [...openSections];
    newOpenSections[index] = !newOpenSections[index];
    setOpenSections(newOpenSections);
    console.log(`Section ${index + 1} is now:`, newOpenSections[index] ? 'OPEN' : 'CLOSED');
  };

  const formatAddress = (address) => {
    if (!address) return '';
    return `${address.toString().slice(0, 4)}...${address.toString().slice(-4)}`;
};

const saveAndClose = async () => {
  setLoading2(true);
  if (tokenName === '' || tokenDescription === '' || tokenSymbol === '' || tokenDecrimals === '' || tokenSupply === '' || !tokenUrl) {
    handleNotification('Please complete all requirements to create your token.');
    setLoading2(false);
    return;
  }
  const result = await uploadJsonToIPFS(tokenName, tokenSymbol, tokenDescription, tokenUrl);
  if (result.error) {
    setLoading2(false);
    console.log(result.error);
  } else {
    setLoading2(false);
    setJsonFile(result);
    handleOpen(1); // Open the first section
    setOpenSections(prevState => prevState.map((isOpen, index) => index === 2 ? true : isOpen)); // Ensure the second section is closed
    console.log('Metadata file url: ', result);
  }
};

  useEffect(() => {
  if (wallet.connected) {
    setOpenSections([true, false, false]);
  }
  }, [wallet.connected]);

  const handleInputs = (field, value) => {
    if(field === 1) {
      if (value.length > 30) {
        handleNotification('Max 30 characters in your name')
        return
      }
      setTokenName(value)
    }
    if(field === 2) {
      if (value.length > 8) {
        handleNotification('Max 8 characters in your symbol')
        return
      }
      setTokenSymbol(value)
    }
    if(field === 3) {
      console.log(value)
      if (value > 9) {
        handleNotification('Max 9 decimals')
        return
      }
      setTokenDecrimals(value)
    }
    if(field === 4) {
      const newValue = value.replace(/\D/g, "");
      console.log(value)
      setTokenSupply(newValue)
    }
    if(field === 5) {
      if (value.length > 8) {
        handleNotification('Max 8 characters in your symbol')
        return
      }
      setTokenSymbol(value)
    }
    if(field === 6) {
      if (value.length > 500) {
        handleNotification('Max 500 characters in your description')
        return
      }
      setTokenDescription(value)
    }
  }

  return (
    <div className="stake">
        <h2>GODLENlauncher</h2>
        <p>Create your own token in 5 minutes with no programming experience</p>
        <div className='stakeTabs'>
        <div id="staking" className="stakingSection">
    <div className='stakingCol'>
        <h3>Create your token</h3>
        <div className="stakingInnerWrap">
            <div className="poolBlocksContainer">
              <div onClick={() => handleOpen(0)} className='poolBoxHeader'>
                  <h3>{wallet.connected ? selectedIcon : selectIcon}<span>Connect wallet</span></h3>
                  <div className='selectRightSection'>
                    {wallet.connected ? <div className='currentWallet'><img height={24} alt="wallet icon" src={walletIcon} /><span>{formatAddress(wallet.publicKey)}</span></div> : null }
                    <button style={{ transform: openSections[0] ? 'rotate(0)' : 'rotate(180deg)' }}>{arrowIcon}</button>
                  </div>
              </div>
              <div className={`selectConnectBody ${openSections[0] ? 'open' : ''}`}>
                  <WalletMultiButton />
              </div>
            </div>
        </div>
        <div className="launcherBlocksContainer">
            <div className='stakingInnerWrap2'>
                <div onClick={() => handleOpen(1)} className='stakingBoxHeader'>
                    <h3>{tokenName !== '' && tokenSymbol !== '' && tokenDecrimals !== '' && tokenSupply !== '' && tokenUrl !== null && tokenDescription !== '' && jsonFile !== null ? selectedIcon : selectIcon}<span>Enter token info</span></h3>
                    <button style={{ transform: openSections[1] ? 'rotate(0)' : 'rotate(180deg)' }}>{arrowIcon}</button>
                </div>
                <div  className={`stakingInput ${openSections[1] ? 'open' : ''}`}>
                        <div className='tokenInfoWrap'>
                          <div className='inputWrapper'>
                            <span>Name:</span>
                            <input onChange={(e) => handleInputs(1, e.target.value)} type='text' placeholder='Enter your token name' value={tokenName} />
                          </div>
                          <div className='inputWrapper'>
                            <span>Symbol:</span>
                            <input onChange={(e) => handleInputs(2, e.target.value)} type='text' placeholder='Enter your token symbol' value={tokenSymbol} />
                          </div>
                          <div className='inputWrapper'>
                            <span>Decrimals:</span>
                            <input onChange={(e) => handleInputs(3, e.target.value)} type='number' placeholder='Enter token decrimals' value={tokenDecrimals} />
                          </div>
                          <div className='inputWrapper'>
                            <span>Supply:</span>
                            <input onChange={(e) => handleInputs(4, e.target.value)} type='number' placeholder='Supply' value={tokenSupply} />
                          </div>
                        </div>
                        <div className='tokenInfoFullWrap'>
                            <ImageUpload file={file} setFile={setFile} tokenUrl={tokenUrl} setTokenUrl={setTokenUrl} />
                            <p>Most meme coin use a squared 1000x1000 logo</p>
                            <textarea onChange={(e) => handleInputs(6, e.target.value)} placeholder='Description (up to 500 symbols)' rows="4" cols="50" value={tokenDescription} />
                        </div>
                    <button onClick={() => saveAndClose()} className='buttonSave'>{loading2 ? 'Saving' : 'Save'} {loading2 ? spinner : null}</button>
                </div>
            </div>
        </div>
        <div className="launcherBlocksContainer">
            <div className='stakingInnerWrap2'>
                <div onClick={() => handleOpen(2)} className='stakingBoxHeader'>
                    <h3>{selectIcon}<span>Add features</span></h3>
                    <button style={{ transform: openSections[2] ? 'rotate(0)' : 'rotate(180deg)' }}>{arrowIcon}</button>
                </div>
                <div  className={`stakingInput ${openSections[2] ? 'open' : ''}`}>
                        <div className='featuresWrap'>
                            <div className='toggleContainer'>
                              <span>Revoke Update (Immutable)</span>
                              <p>Update Authority allows you to update token metadata</p>
                              <div className='toggleWrapper'>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={toggles[0]} 
                                        onChange={() => handleToggle(0)} 
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <div className='toggleDesc'>
                                  <span>Enable</span>
                                  <span>(+0.05 SOL)</span>
                                </div>
                              </div>
                            </div>
                            <div className='toggleContainer'>
                              <span>Revoke Freeze</span>
                              <p>Freeze Authority allows you to freeze token accounts</p>
                              <div className='toggleWrapper'>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={toggles[1]} 
                                        onChange={() => handleToggle(1)} 
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <div className='toggleDesc'>
                                  <span>Enable</span>
                                  <span>(+0.05 SOL)</span>
                                </div>
                              </div>
                            </div>
                            <div className='toggleContainer'>
                              <span>Revoke Mint</span>
                              <p>Mint Authority allows you to mint more supply</p>
                              <div className='toggleWrapper'>
                                <label className="switch">
                                    <input 
                                        type="checkbox" 
                                        checked={toggles[2]} 
                                        onChange={() => handleToggle(2)} 
                                    />
                                    <span className="slider round"></span>
                                </label>
                                <div className='toggleDesc'>
                                  <span>Enable</span>
                                  <span>(+0.05 SOL)</span>
                                </div>
                              </div>
                            </div>
                        </div>
                    <button onClick={() => sendSol()} className='buttonSave'>{processText} {loading ? spinner : null}</button>
                    <div className='feeNotice'>Total Fees: <span>{(creationFee).toFixed(2)} SOL</span></div>
                </div>
            </div>
        </div>
    </div>
    <div className='stakingCol'>
        <div className="launcherOuterWrap">
          <Faqs />
        </div>
    </div>
  </div>
        </div>
    </div>
  );
}

export default LauncherPage;
